@import '../../Styles.scss';

@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

.loading-container {
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    .loading-content {
        text-align: center;
        padding: 2rem;
        border-radius: 2rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .image {
            animation: zoominoutsinglefeatured 1s infinite;
            width: 250px;
            background-color: $primary-color;
            padding: 1rem;
            border-radius: 2px;
        }
    }
}
