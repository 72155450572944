.gallery-section {
    .image {
        padding: 0;
        .image-background {
            width: 100%;
            max-width: 100%;
        }
        .gallery-details {
            border: solid 1px #f5f5f5;
            position: absolute;
            top: 10%;
            left: 5%;
            bottom: 10%;
            right: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            div {
                text-align: center;
            }
            span {
                font-size: 34px;
                font-family: MandalaRegular;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #f8f9fa;
                font-weight: 300;
                text-decoration: none;
            }
            .button {
                padding: 0.5rem 1rem;
                margin-top: 2rem;
                border-radius: 3px;
                background-color: #2a2a2a;
                border: none;
                span {
                    font-size: 14px;
                    margin-left: 0.5rem;
                    font-family: Roboto;
                    vertical-align: middle;
                }
            }
            a:hover {
                text-decoration: none;
            }
        }
    }
    .image:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
    }
}
