@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

@font-face {
    font-family: MandalaBold;
    src: url("./assets/fonts/Mandala-Bold.otf") format("opentype");
}

@font-face {
    font-family: MandalaRegular;
    src: url("./assets/fonts/Mandala-Regular.otf") format("opentype");
}

$primary-color: #4a4a4a;
$second-color: #e94e18;

body {
    font-family: "Roboto", sans-serif;
}

h4 {
    color: $second-color;
    text-align: center;
    font-family: MandalaBold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.separated-section {
    margin-top: 4rem;
}

.title-section {
    margin-bottom: 2rem;
}