@import "../../Styles.scss";

.card {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin-bottom: 2rem;
    img {
        border-top-left-radius: 0.9rem;
        border-top-right-radius: 0.9rem;
        max-height: 200px;
        height: 200px;
        min-height: 200px;
    }
    .card-body {
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
        .card-title {
            font-size: 16px;
            text-align: center;
            color: #2a2a2a;
        }
        .card-text {
            font-size: 14px;
            color: #4a4a4a;
        }
        .view-plus {
            float: right;
            font-size: 14px;
            color: $second-color;
            cursor: pointer;
        }
    }
}
