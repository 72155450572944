@import "../../Styles.scss";

.service-details-section {
    background-color: #f8f9fa;
    .separated-section {
        padding-bottom: 4rem;
        margin-top: 2.5rem;
    }
    .nav-link {
        padding: 0.2rem 0.4rem;
    }
    .nav-tabs {
        padding: 0;
        border-color: transparent;
        border-bottom: solid 2px #dbdbdb;
        a {
            font-size: 14px;
            font-weight: bold;
            color: #717171;
            border: none;
        }
    }
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        border-color: transparent;
        border-bottom: solid 2px $second-color;
        background-color: transparent;
        a {
            border: none;
        }
    }
    .nav-tabs .nav-link:hover {
        border-color: transparent;
        border-bottom: solid 2px $second-color;
        a {
            border: none;
        }
    }
    h4 {
        text-align: left;
        margin-top: 2.5rem;
    }
    .service-details {
        margin-top: 2rem;
        img {
            width: 100%;
            object-fit: contain;
        }
        label {
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #2a2a2a;
            margin-bottom: 1.5rem;
        }
        p {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #717171;
        }
    }
    .desktop{
        visibility: visible;
    }
}

.quotation {
    margin-top: 2rem;
    h4 {
        color: #2a2a2a;
    }
}

.service-details-fragment {
    .footer-section {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .service-details-section {
        .separated-section {
            margin-top: 0rem;
        }
        .title-section{
            margin-bottom: 0rem;
        }
        .desktop{
            visibility: hidden;
        }
    }
}
