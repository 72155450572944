@import "../../Styles.scss";

.alert-container {
    .alert-content {
        z-index: 1;
        text-align: center;
        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 0;
    }
    .alert {
        background-color: $primary-color;
        border: 1px solid $second-color;
        i,
        span {
            color: white;
        }
    }
}

@media screen and (max-width: 768px) {
    .alert-container {
        .alert-content {
            width: 90%;
        }
    }
}
