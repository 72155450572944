@import "../../Styles.scss";

.galleryCollection-section {
    background-color: #f8f9fa;
    padding-bottom: 3rem;
    .separated-section {
        margin-top: 2.5rem;
    }
    h4 {
        text-align: left;
        margin-top: 2.5rem;
    }
    .images {
        .hoverImage {
            visibility: hidden;
        }
        height: 150px;
        overflow: hidden;
        padding: 0;
        img {
            max-width: 100%;
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }
    .images:hover {
        .hoverImage {
            visibility: visible;
            position: absolute;
            top: 0;
            z-index: 2;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            border: 1px solid #f8f9fa;
            margin: 1rem;
            cursor: pointer;
            div {
                height: 2rem;
                width: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(250, 250, 250, 0.5);
                border-radius: 100%;
                margin: auto;
                text-align: center;
                img {
                    height: 15px;
                    width: 15px;
                }
            }
        }
    }
    .images:hover::after {
        content: "";
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $second-color;
        opacity: 0.4;
    }
}

.gallery-collection {
    .footer-section {
        margin-top: 0rem;
    }
}

.gallery-carousel {
    .modal-body {
        padding: 0;
        .carousel {
            margin: 0rem;
            img {
                object-fit: contain;
                width: 100%;
                max-height: 600px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .gallery-carousel {
        .modal-body {
            .carousel {
                img {
                    max-height: 300px;
                }
            }
        }
    }
}
