@import "../../Styles.scss";

.navbar {
    background-color: $primary-color;
    .logo{
        cursor: pointer;
    }
    .item {
        display: flex;
        align-items: center;
        span, a {
            color: white;
            font-size: 14px;
            margin-left: 5px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
        }
    }
    .right-align {
        flex-grow: unset;
    }
    .button-menu {
        border: none;
        overflow: hidden;
        span {
            background-image: url("../../assets/icons/close-light.svg");
        }
    }
    .button-menu:focus {
        outline: none;
    }
    .collapsed {
        span {
            background-image: url("../../assets/icons/bars-solid.svg");
        }
    }
}

.nav-menu {
    float: right;
    .item {
        font-size: 14px;
        color: #2a2a2a;
        font-weight: 500;
        margin-left: 2rem;
        cursor: pointer;
    }
    .active {
        color: $second-color;
    }
}

.whatsapp {
    position: fixed;
    width: 3.5rem;
    height: 3.5rem;
    bottom: 2rem;
    right: 2rem;
    background-color: #25d366;
    color: #fff !important;
    border-radius: 3rem;
    text-align: center;
    font-size: 2.5rem;
    z-index: 100;
}

.desktop {
    display: inherit;
}

.mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .logo {
        width: 150px;
        cursor: pointer;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: inherit;
        background-color: white;
        width: 100%;
        position: fixed;
        z-index: 999;
        left: 0;
        margin-top: 5px;
        height: 100%;
        .info {
            background-color: #333333;
            position: fixed;
            bottom: 0;
            width: 100%;
            padding-top: 1rem;
            .item {
                margin-bottom: 1rem;
            }
        }
        .flex-column {
            padding: 1rem;
            .item {
                padding: 1rem 0;
                border-bottom: solid 1px #979797;
                font-size: 14px;
                color: #2a2a2a;
                font-weight: 500;
                cursor: pointer;
            }
            .active {
                color: $second-color !important;
            }
        }
    }
}
