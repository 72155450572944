@import "../../Styles.scss";

.form-contact {
    border: 1px solid $second-color;
    padding: 3rem 1rem;
    margin-bottom: 2rem;
    .form-control {
        border-radius: 3px;
        border: solid 1px #acacac;
    }
    .form-control::placeholder {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #cfcfcf;
    }
    .button-send {
        text-align: right;
        display: inline-block;
        margin-bottom: 0rem !important;
        button {
            border-radius: 3px;
            background-color: $second-color;
            border: none;
            color: white;
            padding: 0.5rem 3rem;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #f8f9fa;
        }
    }
}

@media screen and (max-width: 768px) {
    .form-contact {
        border: none;
        padding: 0;
    }
}
