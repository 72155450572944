@import "../../Styles.scss";

.footer-section {
    background-color: $primary-color;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .image {
        display: flex;
        align-items: center;
        div {
            text-align: center;
            width: 100%;
            img {
                max-width: 100%;
                width: 250px;
            }
        }
    }
    .footer-navigation {
        text-align: center;
        margin-top: 3rem;
        padding-bottom: 1rem;
        a {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #f8f9fa;
            margin: 1rem;
            cursor: pointer;
        }
        .separated {
            margin: 2rem auto 1rem auto;
            width: 50%;
            border-top: solid 1px #707070;
        }
        .copyright {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #cfcfcf;
        }
    }
}

@media screen and (max-width: 768px) {
    .footer-navigation {
        .options {
            display: grid;
            margin-top: -1rem;
        }
    }
}
