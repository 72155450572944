@import "../../Styles.scss";

.about-section {
    margin-top: 4rem;
    background-color: #f8f9fa;
    .about {
        padding-top: 4rem;
        padding-bottom: 4rem;
        p {
            font-family: Roboto;
            font-size: 14px;
            text-align: center;
            color: #4a4a4a;
            margin-top: 2rem;
            padding-left: 5%;
            padding-right: 5%;
        }
        .item {
            border-radius: 3px;
            border: solid 1px #d6d6d6;
            margin: 0.5rem 5%;
            max-width: 90%;
            padding: 1rem;
            span {
                font-size: 16px;
                font-weight: bold;
                color: #4a4a4a;
            }
            span:after {
                content: "";
                position: absolute;
                left: 1rem;
                top: 2.4rem;
                width: 2rem;
                height: 2px;
                background: $second-color;
            }
            p {
                margin-top: 0.5rem;
                text-align: left;
                font-size: 14px;
                line-height: normal;
                color: #818181;
                padding: 0;
            }
        }
    }
    .padding-not {
        padding: 0;
        margin: auto;
    }
    .image {
        padding: 0;
        .carousel-item {
            transition: none;
            animation-name: backInRight;
            animation-duration: 1s;
        }
        .carousel-about {
            margin-top: 0;
            img {
                width: auto;
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
}

@keyframes backInRight {
    0% {
      transform: translateX(2000px) scale(0.7);
      opacity: 0.7;
    }
  
    80% {
      transform: translateX(0px) scale(0.7);
      opacity: 0.7;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }