.contact-section {
    .company-data {
        padding: 1.5rem;
        .box {
            margin-bottom: 4rem;
            .item {
                align-items: center;
                display: flex;
                span {
                    margin-left: 5px;
                    font-size: 18px;
                    font-weight: bold;
                    color: #4a4a4a;
                }
                margin-bottom: 1rem;
            }
            a {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #2a2a2a;
                cursor: pointer;
            }
        }
    }
}

.map {
    height: 450px;
    padding: 0;
}

@media screen and (max-width: 768px) {
    .company-data {
        text-align: center;
        .box {
            .item {
                display: block !important;
            }
        }
    }
}
